import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const [secrets, setSecrets] = useState(null);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    const fetchSecrets = async () => {
      try {
        console.log('Fetching secrets...');

        // Determine the appropriate Google Analytics token based on the domain
        const domain = window.location.hostname;
        const googleAnalyticsToken =
          domain ===  process.env.REACT_APP_Google_Analytics_Org_Url
            ? process.env.REACT_APP_Google_Analytics_Token_Org
            : domain === process.env.REACT_APP_Google_Analytics_Com_Url
              ? process.env.REACT_APP_Google_Analytics_Token_Com
              : null;

        const fetchedSecrets = {
          "BuyersAwareReactAppGoogleMapsToken": process.env.REACT_APP_Google_Maps_Token,
          "BuyersAwareGoogleAnalyticsToken": googleAnalyticsToken,
          "BuyersAwareReactAppSiteURL": process.env.REACT_APP_Site_URL
        };
        setSecrets(fetchedSecrets);
        window.processEnv = fetchedSecrets; // Set the secrets globally

        // Add Google Analytics script
        if (fetchedSecrets.BuyersAwareGoogleAnalyticsToken) {
          const script = document.createElement('script');
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${fetchedSecrets.BuyersAwareGoogleAnalyticsToken}`;
          document.head.appendChild(script);

          script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', fetchedSecrets.BuyersAwareGoogleAnalyticsToken);
          };
        }

        // Add Google Maps script
        if (fetchedSecrets.BuyersAwareReactAppGoogleMapsToken) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${fetchedSecrets.BuyersAwareReactAppGoogleMapsToken}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            console.log('Google Maps script loaded');
            setIsGoogleMapsLoaded(true); // Set the state to true once the script is loaded
          };
          script.onerror = () => {
            console.error('Error loading Google Maps script');
          };
          document.head.appendChild(script);
        }
      } catch (error) {
        console.error('Error fetching secrets:', error);
      }
    };

    fetchSecrets();
  }, []);

  if (!secrets) {
    console.log('Waiting for secrets...');
  }

  if (!isGoogleMapsLoaded) {
    console.log('Waiting for Google Maps to load...');
  }

  if (!secrets || !isGoogleMapsLoaded) {
    return <div>Loading...</div>; // Show a loading state while fetching secrets and loading Google Maps
  }

  console.log('Secrets and Google Maps loaded, rendering app...');
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

root.render(<Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
